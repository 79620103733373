import { Amplify } from "aws-amplify";

const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: "us-east-1_aR5UuGqXb",
    identityPoolId: "us-east-1:2051669b-4051-41e6-9870-300d6d407011",
    userPoolWebClientId: "18825a8a2iln6bmcpkgolngj8v",
  },
};

export class AmplifyManager {
  static init = () => {
    Amplify.configure(awsConfig);
  };
}

// userPoolId: "us-east-1_YtvLmD3kk",
// identityPoolId: "us-east-1:c3417506-8502-4633-a082-fda582f2e761",
// userPoolWebClientId: "31arf6ibpedd9b7cfl1alb57q7"
