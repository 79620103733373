import React from "react";
import useMessage from "@rottitime/react-hook-message-event";
import "./App.css";
import PayIn from "./components/payIn";
import PayOut from "./components/payOut";
import { OperationType } from "./constants";
import { IWidgetInfo } from "./types";

export const GLOBAL_VALUES = {
  apiKey: "",
};

const App = () => {
  const [widgetInfo, setWidgetInfo] = React.useState<IWidgetInfo>();

  useMessage("INIT", (_send: any, payload: any) => {
    setWidgetInfo(payload.widgetInfo as IWidgetInfo);
    GLOBAL_VALUES.apiKey = payload.widgetInfo!.configuration!.apiKey;
  });

  if (OperationType.PayIn === widgetInfo?.type) {
    return <PayIn widgetInfo={widgetInfo} />;
  }

  if (OperationType.PayOut === widgetInfo?.type) {
    return <PayOut widgetInfo={widgetInfo} />;
  }

  return (
    <PayIn
      widgetInfo={{
        amount: "20",
        clientId: "",
        concept: "",
        documentCode: "",
        documentType: "",
        transactionId: "",
        type: "",
        widgetId: "",
        configuration: {
          maximo: false,
          transfer: true,
          origin: "",
          apiKey: "",
        },
      }}
    />
  );
};

export default App;
